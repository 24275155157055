export const environment = {
  production: false,
  staging: true,
  apiEndpoint: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: 'fdbc9fa2-58ad-437f-909c-d7ada47502a2',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '1a5f44a0-48f1-4a95-9b82-38669c9e51ba',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.staging.services4eo.com/'
};
